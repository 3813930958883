import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import './app-layout.css'
import NavBar from './nav-bar';


const AppLayout: React.FC = () => {

    const [isMobileView, setIsMobileView] = useState(false);

    useEffect(() => {
        const checkIfMobileView = () => {
          if (window.innerWidth <= 768 || window.innerHeight <= 600) {
            setIsMobileView(false);
          } else {
            setIsMobileView(true);
          }
        };
    
        checkIfMobileView();
    
        window.addEventListener('resize', checkIfMobileView);
    
        return () => window.removeEventListener('resize', checkIfMobileView);
      }, []);
  
    return (
        <>
            <div className={isMobileView ?'app-layout-mobile' : 'app-layout-container'  }>
                <Outlet />
                <NavBar />
            </div>
        </>
    );
};

export default AppLayout;
