import { useState } from 'react';
import './add-page.css';

const trophy = require('../assets/icons/trophy.png');
const infinity = require('../assets/icons/infinity.png');
const logo = require('../assets/splash-screen-part2.png');

const movie1 = require('../assets/videos/Movie1.mp4');
const movie2 = require('../assets/videos/Movie2.mp4');

const AddPage = () => {

    const [firstCounter, setFirstCounter] = useState(200);
    const [secondCounter, setSecondCounter] = useState(200);

    
    return (
        <div className="add-page-container">
            <div className="first-video-container">
                <div className='top-button-container'>
                    <img src={trophy} alt="Trophy img" style={{ visibility: 'hidden' }} />
                    <div className='add-container'>
                        <div className='counter'>
                        <span>{firstCounter}</span>
                        </div>
                        <button className='button' onClick={() => setFirstCounter(prevValue => prevValue+1)}>
                            <img src={trophy} alt="Trophy img"/>
                        </button>
                    </div>
                    <img src={logo} alt="Logo"/>
                </div>
                <video muted src={movie1} autoPlay loop playsInline></video>
            </div>
            <div className="separator-container">
                <div className="separator">

                </div>
                <div className="section">
                    <div className="left-section">
                        <span className='user-text'>User 1</span>
                        <span className='user-text'>User 2</span>
                    </div>
                    <div className='mid-section'>
                        <img src={infinity} alt="Infinity Icon" />
                        <div className='text-container'>
                            <span className='round-text'>Round 1</span>
                            <span className='live-text'>LIVE</span>
                        </div>
                    </div>
                    <div className="left-section">
                        <span className='user-text'>User 1</span>
                    </div>
                </div>
                <div className="separator"></div>
            </div>
            <div className="second-video-container">
            <div className='bottom-button-container'>
            <div className='add-container'>
                        <div className='counter'>
                        <span>{secondCounter}</span>
                        </div>
                        <button className='button' onClick={() => setSecondCounter(prevValue => prevValue+1)}>
                        <img src={trophy} alt="Trophy img" />
                        </button>
                    </div>
                </div>
                <video muted src={movie2} autoPlay loop playsInline></video>
            </div>
        </div>
    )
}
export default AddPage;