import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import LoadingSpinner from '../components/loading-spinner';
import './render-page.css';
import AddPage from './add-page';
import ControllerPage from './controller-page';

const srcList = [
    {
        path: 'home',
        src: "https://360sports.muvi.com/",
        title: "360 Sports"
    },
    {
        path: 'add',
        title: 'Add',
        src: 'https://360sports.muvi.com/category/fans'
    },
    {
        path: 'controller',
        title: "Controller",
        component: <ControllerPage />
    },
    {
        path: 'dice',
        src: "https://360bet.realgrowsoft.com/",
        title: "Dice"
    },
    {
        path: 'money',
        src: "https://360money.realgrowsoft.com/",
        title: "Money"
    }
];

const RenderPage = () => {
    const { page } = useParams<{ page: string }>();
    const location = useLocation(); // To detect route changes
    const currentPage = srcList.find((item) => item.path === page);
    const [loading, setLoading] = useState(true);
    const [renderKey, setRenderKey] = useState(0); // Key to force rerender

    useEffect(() => {
        // When currentPage is an iframe, show loader
        if (currentPage?.src) {
            setLoading(true);
        }
    }, [currentPage]);

    useEffect(() => {
        // Force rerender of iframe or component when route changes (e.g. same tab pressed)
        setRenderKey((prevKey) => prevKey + 1);
    }, [location.key]); // Detect route change

    const [iframeHeight, setIframeHeight] = useState('calc(100vh - 80px)');

    useEffect(() => {
        const updateIframeHeight = () => {
            const navbarHeight = 80;
            setIframeHeight(`calc(100vh - ${navbarHeight}px)`);
        };

        updateIframeHeight();
        window.addEventListener('resize', updateIframeHeight);

        return () => {
            window.removeEventListener('resize', updateIframeHeight);
        };
    }, []);

    if (!currentPage) {
        return <div>Page not found</div>;
    }

    return (
        <div key={renderKey} style={{ width: '100%', height: iframeHeight, overflow: 'hidden', backgroundColor: '#570031' }}>
            {loading && currentPage.src ? <LoadingSpinner /> : null}
            {currentPage.src ? (
                <iframe
                    key={renderKey}  
                    src={currentPage.src}
                    title={currentPage.title}
                    style={{ width: '100%', height: iframeHeight, border: 'none' }}
                    onLoad={() => setLoading(false)}
                    allowFullScreen
                />
            ) : currentPage.component ? (
                    currentPage.component
            ) : (
                <div className='not-available-container'>
                    <h2 className='not-available-text'>Under Construction</h2>
                </div>
            )}
        </div>
    );
};

export default RenderPage;
