import { useLocation, useNavigate, NavLink } from "react-router-dom";
import { ReactComponent as DiceIcon } from '../assets/icons/dice-icon.svg';
import { ReactComponent as MoneyIcon } from '../assets/icons/add-icon.svg';
import { ReactComponent as AddIcon } from '../assets/icons/old_add-icon.svg';
import { ReactComponent as PlayIcon } from '../assets/icons/play-icon.svg';
import { ReactComponent as ControllerIcon } from '../assets/icons/controller-icon.svg';
import './nav-bar.css';
import { useEffect, useState } from "react";

interface Tab {
    path: string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const tabs: Tab[] = [
    { path: 'home', icon: PlayIcon },
    { path: 'add', icon: AddIcon },
    { path: 'controller', icon: ControllerIcon },
    { path: 'dice', icon: DiceIcon },
    { path: 'money', icon: MoneyIcon },
];

const NavBar = () => {
    const [isMobileView, setIsMobileView] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();  
    const [forceReload, setForceReload] = useState(true);

    useEffect(() => {
        const checkIfMobileView = () => {
            setIsMobileView(window.innerWidth <= 768 || window.innerHeight <= 600);
        };
        checkIfMobileView();
        window.addEventListener('resize', checkIfMobileView);
        return () => window.removeEventListener('resize', checkIfMobileView);
    }, []);

    const handleTabClick = (path: string) => {
        if (location.pathname === `/app/${path}`) {
           
            navigate(`/app/${path}`, { state: { resetGame: forceReload } });
        } else {
            navigate(`/app/${path}`);
        }
        setForceReload(prev => !prev);
    };

    return (
        <div className={!isMobileView ? 'tabs-container tabs-container-mobile' : 'tabs-container'}>
            {tabs.map((tab, index) => {
                const IconComponent = tab.icon;
                return (
                    <NavLink
                        key={index}
                        to={`/app/${tab.path}`}
                        className={({ isActive }) => `tab ${isActive ? 'active-tab' : ''}`}
                        onClick={() => handleTabClick(tab.path)}
                    >
                        <IconComponent className='tab-icon' />
                    </NavLink>
                );
            })}
        </div>
    );
};

export default NavBar;
