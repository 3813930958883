import './App.css';
import { RouterProvider } from 'react-router-dom';
import router from './router/app-router';
import SplashScreen from './components/splash-screen';
import { useState, useEffect } from 'react';

function App() {
  const [animationIsDone, setAnimationIsDone] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const checkIfMobileView = () => {
      if (window.innerWidth <= 768 || window.innerHeight <= 600) {
        setIsMobileView(false);
      } else {
        setIsMobileView(true);
      }
    };

    checkIfMobileView();

    window.addEventListener('resize', checkIfMobileView);

    return () => window.removeEventListener('resize', checkIfMobileView);
  }, []);

  const handleAnimationDone = () => {
    setAnimationIsDone(true);
  };

 

  return (
    <div className='app'>
      <div className={isMobileView ? "app-container-mobile" : "app-container"}>
        {!animationIsDone ? (
          <SplashScreen isAnimationFinished={handleAnimationDone} />
        ) : (
          <RouterProvider router={router} />
        )}
      </div>
    </div>
  );
}

export default App;
