import { useState } from "react"
import LoadingSpinner from "../components/loading-spinner";
import AddPage from "./add-page";
import './controller-page.css';



interface Game {
    title: string;
    src?: string;
    svg?: React.ReactElement;
    externalLink?: string;
}

const fireicon = require('../assets/icons/fire-icon.png');

const logo = require('../assets/splash-screen-part2.png');

const buffLogo = require('../assets/buff-logo.png');

const backgroundImage = require('../assets/controller-page-background.png')

const battleAppWhite = require('../assets/battle-app-white.png');

const battleIntro = require('../assets/videos/battle-intro.mp4');

const vsgLogo = require('../assets/vsgllogo.png');


const elements: Game[] = [
    {
        title: '360Sports Index',
        src: logo
    },
    {
        title: 'Sports Buff',
        src: buffLogo
    },
    {
        title: 'BattleApp',
        src: battleAppWhite
    },
    {
        title: 'Telkom VS Gaming',
        src: vsgLogo,
        externalLink: 'https://www.vsgaming.co.za/'
    }
]

const ControllerPage = () => {
    const [selectedGame, setSelectedGame] = useState<Game>();
    const [loading, setLoading] = useState(true);
    // const [videoFinished, setVideoFinished] = useState(false);

    const renderScreen = () => {
        if (selectedGame)
            switch (selectedGame.title) {
                case 'Sports Buff':
                    return (
                        <>
                            {loading ? <LoadingSpinner /> : <></>}
                            <iframe
                                src='https://play.sportbuff.com/360sports'
                                title={selectedGame.title}
                                style={{ width: '100%', height: 'calc(100vh - 80px)', border: 'none' }}
                                onLoad={() => setLoading(false)}
                                allowFullScreen
                            />
                        </>
                    )
                case 'BattleApp':
                    // if (videoFinished) {
                        return <AddPage />;
                    // }
                    // return (
                    //     <div className="video-container">
                    //         <video
                    //             src={battleIntro}
                    //             width="100%"
                    //             height="100%"
                    //             autoPlay
                    //             onEnded={() => setVideoFinished(true)}
                    //             controls={false}
                    //             style={{ objectFit: 'fill' }}
                    //         />
                    //     </div>
                    // );

                case '360Sports Index':
                    return (
                        <>
                            {loading ? <LoadingSpinner /> : <></>}
                            <iframe
                                src='https://beta.asx.ai/'
                                title={selectedGame.title}
                                style={{ width: '100%', height: '100%', border: 'none' }}
                                onLoad={() => setLoading(false)}
                                allowFullScreen
                            />
                        </>
                    )
                case 'Telkom VS Gaming':
                    return (
                        <>
                            <a href='' target="_blank" rel="noopener noreferrer"></a>
                        </>
                    )


            }
    }

    return (
        <div className="controller-page-container">
            {selectedGame ? renderScreen() : <>
                <div className="header">
                    <img src={logo} alt="Logo" />
                    <span className="header-text">GAMES</span>
                    <img src={logo} alt="Logo" style={{ visibility: 'hidden' }} />
                </div>
                <div className="image-container">
                    <img src={backgroundImage} alt="Background Image" />
                </div>
                <div className="games-section">
                    <div className="games-title">
                        <img src={fireicon} alt="" />
                        <span >Top Games</span>
                    </div>
                    <div className="games-container">

                        {elements.map((item, idx) => {
                            return (
                                <div key={idx} className="game-container" onClick={(event) => { 
                                    if (item.externalLink) {
                                        event.preventDefault();
                                        window.open(item.externalLink,'_blank')
                                    } else {
                                    setSelectedGame(item) 
                                }
                                    }}>
                                    <div className="content">
                                        <img src={item.src} alt="Logo" />
                                    </div>
                                    <span className="title">
                                        {item.title}
                                    </span>
                                </div>)
                        })
                        }
                    </div>
                </div>
            </>
            }
        </div>
    )
}

export default ControllerPage;