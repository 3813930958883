import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('service-worker.js').then((registration: ServiceWorkerRegistration) => {
//       console.log('ServiceWorker registration successful with scope: ', registration.scope);
      
//       // Send the user agent to the Service Worker
//       if (navigator.serviceWorker.controller) {
//         navigator.serviceWorker.controller.postMessage({
//           type: 'SET_USER_AGENT',
//           userAgent: navigator.userAgent,
//         });
//       }
//     }).catch((error: Error) => {
//       console.error('ServiceWorker registration failed:', error);
//     });
//   });
// }


root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
