
import { useNavigate } from 'react-router-dom';
import './auth-page.css';
import { useState, useEffect } from 'react';

const bgImage = require('../assets/bg-image.png');
const logo = require('../assets/splash-screen-part2.png');

const AuthPage = () => {
    const navigate = useNavigate();

    const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const checkIfMobileView = () => {
      if (window.innerWidth <= 768 || window.innerHeight <= 600) {
        setIsMobileView(false);
      } else {
        setIsMobileView(true);
      }
    };

    checkIfMobileView();

    window.addEventListener('resize', checkIfMobileView);

    return () => window.removeEventListener('resize', checkIfMobileView);
  }, []);


    const handleContinue = () => {
        navigate('/app/home');
    }

    return (
        <div className={isMobileView ? "auth-page-container auth-page-container-mobile" : "auth-page-container"}>
            <div className='background-image'>
                {/* <img src={bgImage} alt="Bg Image" /> */}
            </div>
            <div className='logo-image'>
                <img src={logo} alt="Bg Image" />
            </div>
            <p className='description'>Endless entertaiment with streaming, gaming, and betting all in one place.</p>
            <div className='buttons-container'>
                <button className='signup-button'>SIGN UP NOW</button>
                <button className='login-button'>LOGIN</button>
                <span className='continue-link' onClick={handleContinue}>Continue without sign in -{'>'}</span>
            </div>
        </div>
    )
}


export default AuthPage;