import { useState, useEffect } from "react";
import './splash-screen.css'
import Lottie from "lottie-react";
import animationData from '../assets/360sports 1.json';




const splashScreenPart1 = require('../assets/splash-screen-part1.png');
const splashScreenPart2 = require('../assets/splash-screen-part2.png');

interface SplashScreenProps {
    isAnimationFinished: (done: boolean) => void;
}


const SplashScreen: React.FC<SplashScreenProps> = ({ isAnimationFinished }) => {
   
    useEffect(() => {
        const timer1 = setTimeout(() => {
           
            isAnimationFinished(true);
        }, 3500);

        

        return () => {
            clearTimeout(timer1);
     
        };
    }, []);

  
        return (<div >
            <Lottie animationData={animationData} loop={false} height={932} width={430} />
        </div>
        );
        
};


export default SplashScreen;