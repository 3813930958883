import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import AuthPage from "../views/auth-page";
import AppLayout from "../components/app-layout";
import RenderPage from "../views/render-page";



const router = createBrowserRouter([
    {
        path: '/',
        element: <Navigate to="/auth" replace />
    },
    {
        path: '/app',
        element: <AppLayout />,
        children: [
            {
                path: ':page',  
                element: <RenderPage />
            }
        ]
    },
    {
        path: '/auth',
        element: <AuthPage />
    }
])


export default router;